// extracted by mini-css-extract-plugin
export var fadeIn = "b_d";
export var featuresSection = "b_j";
export var featuresSectionImage = "b_k";
export var headSection = "b_b";
export var headSectionImage = "b_f";
export var headSectionLogo = "b_c";
export var icons = "b_m";
export var relatedProductsSection = "b_l";
export var textSection = "b_g";
export var textSectionVideo = "b_h";